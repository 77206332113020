import { FunctionComponent } from 'react'

const DEFAULT_EXPORT_NAME = '_default'

export type TrackingComponent = string | FunctionComponent<any>

export function componentNameFrom(component: TrackingComponent): string {
  const name = typeof component === 'string' ? component : (component.displayName || component.name)
  if (name.trim().length === 0 || name === DEFAULT_EXPORT_NAME) {
    throw new Error(`Invalid component name supplied: ${name}`)
  }

  return name
}
