import { componentNameFrom, TrackingComponent } from 'analytics/OmniTracker/TrackingComponent'

export interface TrackingIdProvider {
  /**
   * Generates the ID of an input field. This can be anything that conceptually is related to user
   * input - not just HTML `<input>` or `<select>`. The result from this method is supposed to be
   * set as the HTML `id` of the input in question. This ensures uniform tracking event naming.
   *
   * Usage example:
   * ```js
   * <input type="text" id={tracker.inputId(MyComponent, 'email')} name="email" />
   * ```
   *
   * @param component The component the field is located within
   * @param fieldName The name of the field, such as `title`, `comment` or `slotType`
   * @return The resulting ID to be used on the element.
   */
  inputId(component: TrackingComponent, fieldName: string): string

  /**
   * Generates the ID of an action element.
   *
   * This can be a `<button>`, an `<a>` or an `<input type="button|submit" />`
   *
   * Usage example:
   * ```js
   * <button type="button" id={tracker.inputId(MyComponent, 'email')}>Click me!</button>
   * ```
   *
   * @param component The component the element is located within
   * @param action The action this element performs when clicked by the user.
   *  Such as `next`, `submit`, `cancel`, `back`, ...
   * @return The resulting ID to be used on the element.
   */
  actionId(component: TrackingComponent, action: string): string
}

export default class BaseTrackingIdProvider implements TrackingIdProvider {
  /**
   * Generates the ID of an input field. This can be anything that conceptually is related to user
   * input - not just HTML `<input>` or `<select>`. The result from this method is supposed to be
   * set as the HTML `id` of the input in question. This ensures uniform tracking event naming.
   *
   * Usage example:
   * ```js
   * <input type="text" id={tracker.inputId(MyComponent, 'email')} name="email" />
   * ```
   *
   * @param component The component the field is located within
   * @param fieldName The name of the field, such as `title`, `comment` or `slotType`
   * @return The resulting ID to be used on the element.
   */
  inputId(component: TrackingComponent, fieldName: string): string {
    return `${componentNameFrom(component)}_${fieldName}`
  }

  /**
   * Generates the ID of an action element.
   *
   * This can be a `<button>`, an `<a>` or an `<input type="button|submit" />`
   *
   * Usage example:
   * ```js
   * <button type="button" id={tracker.actionId(MyComponent, 'email')}>Click me!</button>
   * ```
   *
   * @param component The component the element is located within
   * @param action The action this element performs when clicked by the user.
   *  Such as `next`, `submit`, `cancel`, `back`, ...
   * @return The resulting ID to be used on the element.
   */
  actionId(component: TrackingComponent, action: string): string {
    return `${componentNameFrom(component)}_${action}`
  }
}
