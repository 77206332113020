import OmniTracker from 'analytics/OmniTracker'
import singleton from 'shared/util/singleton.util'

export type {
  TrackingEvent, FixedComponentTracker, FixedComponentTrackingEvent, FixedAppTracker, FixedAppTrackingEvent,
} from 'analytics/OmniTracker'
export { AppName } from 'analytics/OmniTracker'

/**
 * Root tracker object for the frontend. Abstracts away differences between tracking technologies
 * and provides a way to manually track events & generate standardized tracking IDs for buttons &
 * other elements. See {@link OmniTracker} for more info.
 *
 * Typical usage:
 * -------------
 *
 * In a file specific to the current app / entry point (e.g. `my_app/tracker`):
 *
 * ```js
 * import Tracker, { AppName } from 'analytics/tracker'
 *
 * export default Tracker.forApp(AppName.MY_APP)
 * ```
 *
 * In a component file:
 *
 * ```js
 * import Tracker from 'my_app/tracker'
 *
 * // Note that the tracker can (and should) be instantiated outside of the react component
 * const tracker = Tracker.forComponent(MyComponent)
 *
 * function MyComponent() {
 *   const onSomethingHappened = useCallback(() => tracker.track('Something', 'happened'), [])
 *   return <Component onSomething={onSomethingHappened} />
 * }
 * ```
 */
const Tracker = singleton('OmniTracker', () => new OmniTracker())

export default Tracker
